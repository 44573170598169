<template>
  <div class="wrapper">
    <tree-to-vliew :addPerson="true" @getId="getId"></tree-to-vliew>
    <create-template :uploadStatus="params" v-if="sentry_id">
      <addPerson :sentryId="sentry_id"></addPerson>
    </create-template>
    <right-content  v-else >
      <div class="notiry">请选择左边栏监督岗进行录入人员</div>
    </right-content>
  </div>
</template>

<script>
import { isPhoneNum } from "@/utils/validate";
import UploadImg from "@/components/UploadImg";
import { addInformation } from "@/api";
import CreateTemplate from "@/views/commonViews/CreateTemplate";
import TreeToVliew from "@/views/commonViews/TreeToVliew";
import RightContent from '../../commonViews/RightContent';
import addPerson from './addPerson'
export default {
  name: "supervision",
  components: {
    TreeToVliew,
    CreateTemplate,
    UploadImg,
    RightContent,
    addPerson
  },
  methods: {
    getId(id){
      this.sentry_id = id
      this.params.request.sentry_id = id
    },
    filterData() {
      let data = {};
      Object.keys(this.form).forEach((item) => {
        data[item] = this.form[item];
      });
      return data;
    },
    async submit() {
      try {
        if (!isPhoneNum(this.form.phone_number)) {
          throw new Error("手机格式错误");
        }
        const requestData = {
          type: 3,
          value: this.filterData(),
        };
        const res = await addInformation(requestData);
        if (res.status == 200) {
          this.$success("添加成功");
          this.$router.push({
            path: "/company",
          });
        }
      } catch (e) {
        if (e instanceof Error) {
          this.$error(e.message);
        }
      }
    },
  },
  data() {
    return {
      params:{
        request:{},
        name:"users"
      },
      sentry_id:0,
      form: {
        img: "",
        name: "",
        found_time: "",
        people_name: "",
        phone_number: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  background-color: #f8f8f8;
  display: flex;
  height: 100%;
  .notiry{
    margin: 200px auto;
    transform:translateX(-100px);
    font-size: 40px;
    color: red;
  }
}
</style>
